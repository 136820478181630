import React, { FC } from 'react';
import clsx from 'clsx';
import ReactLoading from 'react-loading';

import styles from './Loader.module.scss';

const LOADER_TYPE = 'bubbles';
const LOADER_COLOR = styles.$loaderColor;
const LOADER_SIZE = 80;

interface LoaderProps {
  isButtonLoader?: boolean;
  color?: string;
  size?: number;
}

const Loader: FC<LoaderProps> = ({ isButtonLoader, color = '#795af7', size }) => (
  <ReactLoading
    type={LOADER_TYPE}
    color={color || LOADER_COLOR}
    width={size || LOADER_SIZE}
    height={size || LOADER_SIZE}
    className={clsx(isButtonLoader && styles.loader)}
  />
);

export default Loader;
